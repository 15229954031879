@media (max-width: 1199px) and (min-width: 992px) {
    .container {
        max-width: 1000px;
    }


}

@media (max-width: 991px) and (min-width: 768px) {
    .container {
        max-width: 100%;
    }


}

@media (max-width: 767px) {
    .container {
        max-width: 100%;
    }

    .mobile-view {
        display: flex !important;
    }

    .destop-view {
        display: none !important;
    }

    .left-section {
        height: inherit;
    }

    .right-section {
        height: inherit;
    }

    .right-section form {
        padding-bottom: 20px !important;
        margin: 0px !important;
        width: 100%;

    }

    .left-section {
        justify-content: center;
    }

    .Verify-otp {
        height: 54vh !important;
    }

    .right-top-section {
        margin-top: 20px !important;
    }

    /* .progress {
        position: absolute;
        left: 20%;
        bottom: 0px;
    } */
    #nt-1 {
        height: 67vh !important;
    }

    .btn-primary-01 {
        margin: 0px;
    }

    .size-n2 {
        height: 290px;
    }
        button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd.MuiIconButton-sizeMedium.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
            display: flex !important;
            position: relative;
            z-index: 10;
        }
}