@font-face {
    font-family: "ChelseaMarket-Regular";
    src: url("../fonts/chelsea_market/ChelseaMarket-Regular.ttf");
}

@font-face {
    font-family: "Muli-Regular";
    src: url("../fonts/muli/Muli-Regular.ttf");
}

@font-face {
    font-family: "Muli-SemiBold";
    src: url("../fonts/muli/Muli-SemiBold.ttf");
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    font-family: "Muli-Regular";
    font-size: 12px;
    margin: 0;
    color: #fff;
}

input,
textarea,
select,
button {
    font-family: "Muli-SemiBold";
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin: 0;
}




.custom-radio-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: "Lato", sans-serif;
}

.checkbox-title {
    font-size: 1.2rem;
}

.custom-label {
    user-select: none;
    z-index: 10;
    margin: 0 10px 10px 0;
    position: relative;
    color: #ced4da;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
    font-weight: bold;
    background-color: #ffffff;
    border: 2px solid #ced4da;
    cursor: pointer;
    transition: all 200ms ease;
    padding: 5px 20px;
    border-radius: 10px;
}

.custom-radio-container input[type="radio"] {
    display: none;
}

.custom-radio-container input[type="radio"]:checked+.custom-label {
    color: #495057;
    background-color: #ced4da;
}

img {
    max-width: 100%;
}

ul {
    padding-left: 0;
    margin-bottom: 0;
}

a {
    text-decoration: none;
    color: #fff;
    font-size: 13px;
}

:focus {
    outline: none;
}

.wrapper {
    min-height: 100vh;
    display: flex;
    background: #854857;
}

.wrapper .image-holder {
    width: 69.9%;
    height: 100vh;
    position: fixed;
}

.wrapper .form-inner {
    width: 30.1%;
    position: absolute;
    right: 0px;
    height: inherit;
}

.image-holder {
    background: url("../images/registration-form.jpeg") no-repeat;
    background-size: cover;
    position: relative;
}

.image-holder img {
    /* display: none; */
    position: fixed;
    height: 150px;
    width: auto;
    top: 70px;
    left: 0px;
    right: 0px;
    margin: 0 auto;
}

.form-inner {
    /* background: #841b2d; */
    background: #854857;
    padding-top: 70px;
    padding-left: 4vw;
    padding-right: 4vw;
}

form {
    width: 100%;
    margin: 60px 0px 0px;
}

.form-header {
    text-align: center;
    margin-bottom: 39px;
}

.form-header ul {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    list-style: none;
}

.form-header ul li {
    margin-right: 20px;
    background: none;
    color: #fff;
    padding: 13px;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    line-height: 10px;
}

.form-header ul li.filled {
    background: #fff;
    color: #000;
    border-radius: 50%; /* Makes the disc a round circle */
    width: 35px; /* Adjust as needed */
    height: 35px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-header ul li.notfilled {
    color: #fff;
    border-radius: 50%; /* Makes the disc a round circle */
    width: 35px; /* Adjust as needed */
    height: 35px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

h3 {
    text-transform: uppercase;
    font-size: 18px;
    text-decoration: underline;
    color: #fff;
}

label {
    margin-bottom: 11px;
    display: block;
    color: #fff;
    font-size: 15px;
}

.form-group {
    margin-bottom: 26px;
    position: relative;
}

.form-control {
    border: 1px solid rgba(255, 255, 255, 0.5);

    border-radius: 5px !important;

    display: block;
    width: 100%;
    height: 35px;
    background: none;
    padding: 4px 19px !important;

    color: #fff;
    font-size: 17px;
}

.form-control.error {
    border-color: #ff0000 !important;
    background: url("../images/error.png") no-repeat center right 19px;
}

.form-control.valid {
    background: url("../images/valid.png") no-repeat center right 19px;
}

.form-error {
    margin-top: 10px;
    display: inline-block;
}

.display-flex {
    display: flex;
    justify-content: space-between;
}

.checkbox-circle label {
    cursor: pointer;
    display: inline-block;
    padding-left: 23px;
    position: relative;
    margin-right: 0;
    width: auto;
}

.checkbox-circle label input {
    width: auto;
    height: auto;
}

button {
    border: none;
    width: 100%;
    height: 46px;
    border-radius: 22.5px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #fff;
    color: #000;
    text-transform: uppercase;
    font-size: 17px;
    overflow: hidden;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

button:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50%;
    transform-origin: 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

button:hover {}

button:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.socials {
    text-align: center;
    margin-top: 59px;
}

.socials-icon {
    display: inline-flex;
    width: 41px;
    height: 41px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 19px;
    color: #fff;
    transition: all 0.5s ease;
    margin-right: 19px;
}

.socials-icon:hover {
    background: #fff;
    border: 1px solid transparent;
    color: #ff97a4;
}

.socials-icon:last-child {
    margin-right: 0;
}

p {
    font-family: "Muli-SemiBold";
    color: #ffff66;
    margin-bottom: 22px;
}

@media (max-width: 1500px) {
    .form-inner {
        /* display: flex; */
        align-items: center;
        padding-top: 0;
        padding-left: 4vw;
        padding-right: 4vw;
    }
}

@media (max-width: 1199px) {
    .wrapper {
        flex-direction: column;
    }

    .wrapper .image-holder {
        width: 100%;
        height: 45vh;
        background-position: center;
    }

    .image-holder img {
        margin: 20px auto;
    }

    .wrapper .form-inner {
        width: 100%;
        bottom: 27%;
        height: auto;
    }

    .form-inner {
        padding-left: 20vw;
        padding-right: 20vw;
    }

    button {
        width: 50%;
    }
}

/* @media (max-width: 1050px) {
     .image-holder img {
        display: block;
        height:300px;
        width: auto;
        position: absolute;
        left: 350px;
    }
} */
@media (max-width: 991px) {
    .wrapper .image-holder {
        height: 35vh;
        background: #854857;
    }

    .image-holder img {
        display: block;
        height: 200px;
        width: auto;
        position: absolute;
        /* top: 130px; */
        left: 0px;
    }


    .wrapper .form-inner {
        height: auto !important;
    }

    .socials {
        margin-top: 40px;
    }

    .form-header {
        margin-bottom: 30px;
    }

}

@media (max-width: 767px) {
    .wrapper .form-inner {
        height: auto;
        position: absolute;
        top: 25%;
        z-index: 99;
    }

    .wrapper .image-holder {

        height: 50vh;
        position: absolute;
    }

    .image-holder img {
        display: block;
        height: 100px;
        width: auto;
        position: absolute;
        left: 0px;
    }

    .form-inner {
        padding: 30px 20px;
    }

    button {
        width: 100%;
    }

    .form-control {
        width: 330px;
    }

    .choices__input {
        width: 330px;
    }

    .kwt-file {
        max-width: 330px !important;
    }
}

@media (max-width: 500px) {
    .image-holder img {
        display: block;
        height: 110px;
        width: auto;
        position: absolute;
        left: 0px;

        top: 60px;

    }

    .form-inner {
        /* bottom: 200px; */
    }

}


@media (max-width: 400px) {
    .image-holder img {
        display: block;
        height: 90px;
        width: auto;
        position: absolute;
        left: 0px;

        top: 35px;

    }

    .form-inner {
        /* bottom: 200px; */
    }

}

.btn {
    border: none;
    width: 100%;
    height: 46px;
    border-radius: 22.5px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: #fff;
    color: #000;
    text-transform: uppercase;
    font-size: 17px;
    overflow: hidden;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
}

.btn:hover {
    background: #000;
    color: #fff;
}

.btn-box {
    display: flex;
    column-gap: 10px;
}


.form-holder .form-control option {
    color: #000;
}

.form-row #choices-multiple-remove-button option {
    color: #000;
}

.mt-100 {
    margin-top: 100px
}

.choices__inner {
    background: none !important;
    padding: 0px !important;
    min-height: 37px !important;
}

.choices__input {
    background: none !important;
}

.choices__list--multiple {
    display: flex !important;
    flex-wrap: wrap;
}

.choices__list--multiple .choices__item {
    background: none !important;
    border: 1px solid #fff !important;
    padding: 0px 10px !important;
    border-radius: 0px !important;
    font-size: 9px !important;
    margin: 5px 5px;
    height: 30px;
    align-items: center;
    text-align: center;
    display: flex !important;
    width: max-content;
}




.choices__list--dropdown .choices__item {
    color: #000 !important;
}

.rmsc .dropdown-container {
    background-color: none;
}

.choices[data-type*=select-multiple] .choices__button {
    border-left: 0px solid #fff !important;
}

.dropdown-heading-value span {
    color: #fff !important;
}

.dropdown-heading-value svg path {
    color: #fff !important;
}

body {
    background: #ffffff;
    background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

* {
    box-sizing: border-box;
    margin: 0;
    
    padding: 0;
    color: black;
}

*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

*:before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.container {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: 32px;
    font-weight: 600;
    color: #000;
    margin-bottom: 42px;
    position: relative;
    padding-bottom: 20px;
}

/* h1:after {
    content: "";
    width: 140px;
    height: 4px;
    background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    border-radius: 6px;
} */

code {
    color: red;
}

.plugin-setting-list li {
    position: relative;
    padding-left: 30px;
    font-size: 16px;
    color: #000000;
    list-style-type: none;
    margin-bottom: 15px;
}

.plugin-setting-list li:before {
    content: "";
    position: absolute;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-check' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='%23ffffff' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'%3E%3C/path%3E%3Cpath d='M5 12l5 5l10 -10'%3E%3C/path%3E%3C/svg%3E");
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #3cb371;
    border-radius: 100%;
}

/* Plugin Style Start */
.kwt-file {
    max-width: 380px;
    /* margin: 0 auto; */
    padding-bottom: 5px;
}

.kwt-file__drop-area {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    transition: 0.3s;
}

.kwt-file__drop-area.is-active {
    background-color: #d1def0;
}

.kwt-file__choose-file {
    flex-shrink: 0;
    background-color: #1d3557;
    border-radius: 100%;
    margin-right: 10px;
    color: #ffffff;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.kwt-file__choose-file.kwt-file_btn-text {
    border-radius: 4px;
    width: auto;
    height: auto;
    padding: 10px 20px;
    font-size: 14px;
}

.kwt-file__choose-file svg {
    width: 12px;
    height: 12px;
    display: block;
}

.kwt-file__msg {
    color: #1d3557;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.kwt-file__input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    opacity: 0;
}

.kwt-file__input:focus {
    outline: none;
}

.kwt-file__delete {
    display: none;
    position: absolute;
    right: 10px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.kwt-file__delete:before {
    content: "";
    position: absolute;
    left: 0;
    transition: 0.3s;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%231d3557' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 438.5 438.5'%3e%3cpath d='M417.7 75.7A8.9 8.9 0 00411 73H323l-20-47.7c-2.8-7-8-13-15.4-18S272.5 0 264.9 0h-91.3C166 0 158.5 2.5 151 7.4c-7.4 5-12.5 11-15.4 18l-20 47.7H27.4a9 9 0 00-6.6 2.6 9 9 0 00-2.5 6.5v18.3c0 2.7.8 4.8 2.5 6.6a8.9 8.9 0 006.6 2.5h27.4v271.8c0 15.8 4.5 29.3 13.4 40.4a40.2 40.2 0 0032.3 16.7H338c12.6 0 23.4-5.7 32.3-17.2a64.8 64.8 0 0013.4-41V109.6h27.4c2.7 0 4.9-.8 6.6-2.5a8.9 8.9 0 002.6-6.6V82.2a9 9 0 00-2.6-6.5zm-248.4-36a8 8 0 014.9-3.2h90.5a8 8 0 014.8 3.2L283.2 73H155.3l14-33.4zm177.9 340.6a32.4 32.4 0 01-6.2 19.3c-1.4 1.6-2.4 2.4-3 2.4H100.5c-.6 0-1.6-.8-3-2.4a32.5 32.5 0 01-6.1-19.3V109.6h255.8v270.7z'/%3e%3cpath d='M137 347.2h18.3c2.7 0 4.9-.9 6.6-2.6a9 9 0 002.5-6.6V173.6a9 9 0 00-2.5-6.6 8.9 8.9 0 00-6.6-2.6H137c-2.6 0-4.8.9-6.5 2.6a8.9 8.9 0 00-2.6 6.6V338c0 2.7.9 4.9 2.6 6.6a8.9 8.9 0 006.5 2.6zM210.1 347.2h18.3a8.9 8.9 0 009.1-9.1V173.5c0-2.7-.8-4.9-2.5-6.6a8.9 8.9 0 00-6.6-2.6h-18.3a8.9 8.9 0 00-9.1 9.1V338a8.9 8.9 0 009.1 9.1zM283.2 347.2h18.3c2.7 0 4.8-.9 6.6-2.6a8.9 8.9 0 002.5-6.6V173.6c0-2.7-.8-4.9-2.5-6.6a8.9 8.9 0 00-6.6-2.6h-18.3a9 9 0 00-6.6 2.6 8.9 8.9 0 00-2.5 6.6V338a9 9 0 002.5 6.6 9 9 0 006.6 2.6z'/%3e%3c/svg%3e");
}

.kwt-file__delete:after {
    content: "";
    position: absolute;
    opacity: 0;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scale(0);
    background-color: #1d3557;
    border-radius: 50%;
    transition: 0.3s;
}

.kwt-file__delete:hover:after {
    transform: translate(-50%, -50%) scale(2.2);
    opacity: 0.1;
}

/* body{background: #00B4DB;background: -webkit-linear-gradient(to right, #0083B0, #00B4DB);background: linear-gradient(to right, #0083B0, #00B4DB);color: #514B64;min-height: 100vh} */



.file-input-container {
    width: 100%;
    /* Full width of the parent container */
}

.file-input-label {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: white;
    /* Background color for the file input area */
    border: 1px solid #ced4da;
    /* Bootstrap border color */
    border-radius: 4px;
    /* Rounded corners */
    position: relative;
    height: 45px;
}

.rmsc {
    font-family: "Muli-Regular" !important;

}

.rmsc .dropdown-container {
    border: none !important;
    height: 35px;
    background-color: transparent !important;
    /* Set background to transparent */
}

.rmsc .clear-selected-button {
    display: none !important;
}

.rmsc * {
    font-size: 15px;
}


.multiselect-anilesh {
    width: 100%;
    border: 1px solid #ced4da;
    /* Bootstrap border color */
    border-radius: 5px;
}

.clip-icon {
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 50%;
    font-size: 24px;
    width: 20%;
    /* Clip icon takes up 20% of the width */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-input {
    width: 80%;
    /* File input area takes up 80% of the width */
    opacity: 0;
    /* Hide the default file input */
    position: absolute;
    /* Position it over the placeholder */
    left: 20%;
    /* Start after the clip icon */
    height: 100%;
    /* Full height of the label */
}

.placeholder-text {
    /* width: 80%; */
    text-align: center;
    color: #1d3557;
    line-height: 40px;
    height: 100%;
    position: absolute;
    left: 060px;
    top: 0;
    pointer-events: none;
    font-size: 16px;
    overflow: hidden;
}


.clip-image {
    max-width: 100%;
    margin-left: 15px;
    height: auto;
    width: 25px;
    object-fit: contain;
}


.shadow {
    box-shadow: 0 .5rem 1rem #00000026 !important;
    --bs-bg-opacity: 1;
    background-color: rgba(248, 249, 250, var(--bs-bg-opacity));
    width: 100%;
    text-align: center;
    border-radius: 10px;
}

.sec-text {
    color: #000;
    font-size: 14px;
}

/*# sourceMappingURL=style.css.map */



.image-preview {
    width: 100%;
    border-radius: 0px 0px 10px 10px;
    height: auto;
    border: 1px solid rgba(255, 255, 255, 0.5);
    padding: 10px;
    margin-top: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
}