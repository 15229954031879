body {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.phone-no-input-container {
  border: 2px solid black;
  padding: 0.5rem;
  border-radius: 5px;
  width: min(60vwvw, 600px);
  margin-top: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .5em;
}

.phone-no-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: .5em .75em;
  border-radius: 20px;


}

/* CSS for blinking effect */
@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }
}

.blinking-text {
  animation: blink 1s infinite;
  /* Adjust timing as needed */
}

#qr-shaded-region {
  position: absolute;
  border-width: 35px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.48);
  box-sizing: border-box;
  inset: 0px;
}

/* Print styles */
@media print {
  body.print-only .no-print {
      display: none !important;
  }
  #printable-content {
      display: block !important;
  }
}


#reader video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Makes the video fill the div without stretching */
  position: absolute;
  /* Fills the entire #reader div */
  top: 0;
  left: 0;
}



.imge-section {
  /* background: url(./assets/print_demo.c0bc17be2d6f7ff27b7a.png); */
  background-repeat: no-repeat;
  background-size: contain;
  height: 450px;
  width: 300px;
  position: relative;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(248, 249, 250, var(--bs-bg-opacity));
  width: 100%;
  margin: 20px 0px 40px;
}

.card-title {
  color: var(--bs-card-title-color);
  margin-bottom: var(--bs-card-title-spacer-y);
}

.phone-no-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 49, 49);
  display: inline-block;
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: .5em;
  font-size: 18px;



}


.phone-no-input {
  flex-grow: 1;
  padding: .5em 0;
  border: none;
  outline: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.data-table th {
  background-color: #3d4156 !important;
  color: #fff !important;
  border: solid 1px #fff !important;
}

.data-table td {
  background-color: #e0e0e0 !important;
  border: solid 1px #fff !important;
}

.exhibitorlogin {
  width: 450px;
  /* padding: 60px 0; */
  margin: auto auto;
  text-align: center;
  box-sizing: border-box;
}

.exhibitorloginbody {
  position: relative;
  padding: 35px 30px 10px;
  margin-bottom: 1em;
  background-color: #fff;
  border: 1px solid #ddd;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.form-group .photo-error {
  color: red !important;
  font-size: 13px !important;
  margin-bottom: 0 !important;
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  background-image: linear-gradient(to bottom, #1f88a4 0%, #0a7693 100%);
}

@media (max-width: 576px) {
  .exhibitorlogin {
    max-width: 300px;
    margin: 0 auto;
  }
}

.btn-color {
  background-color: #e0e0e0 !important;
  color: black !important;
  border-color: black !important;
}

.download-pics-td {
  padding-left: 50px !important;
  width: 30% !important;
  height: 23px !important;
}

.download-pics-checkbox {
  width: 20px !important;
  height: 20px !important;
}

.download-pics-form {
  max-width: 450px;
  margin: 0 auto;
}

.download-pics-form .form-group {
  display: flex;
  align-items: center;
}

.download-pics-form .form-group .form-label {
  width: 70% !important;
}

.rdw-editor-main {
  border: solid 1px #ccc;
  height: 200px;
}

.email-sms-setting {
  max-width: 80% !important;
  margin: 0 auto !important;
}

.email-sms-setting .mail-text-left {
  width: 25%;
}

img {
  max-width: 100%;
}

.footer {
  /* height: 50px !important; */
  background-color: rgb(61, 65, 86) !important;
  color: #fff;
  text-align: center;
  padding: 20px 0px;
}

.nodesure::-webkit-scrollbar {
  width: 5px;
  margin-left: 3px;
}

/* Track */
.nodesure::-webkit-scrollbar-track {
  background: #a5afae;
  border-radius: 10px;
}

/* Handle */
.nodesure::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
.nodesure::-webkit-scrollbar-thumb:hover {
  background: #2e635d;
}

.nodesure {
  width: -webkit-fill-available;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  top: 35px;
}

.switch-button {
  background-color: #0a7693;
  height: 20px;
  width: 20px;
}

.modal-img {
  max-width: 300px;
  max-height: 300px;
}

.img-size {
  height: 40px !important;
  width: 40px !important;
  cursor: pointer !important;
}

.exhibitor-modal {
  background-color: #fff !important;
}

.css-e784if-MuiTypography-root {
  display: none;
}

.cab-service-upload {
  margin-bottom: -2rem !important;
}

.pointer-cursor {
  cursor: pointer;
}

.form-body {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 10%), 0 1px 2px -1px rgb(0, 0, 0, 10%);
  border: solid 1px #c8cff5;
  border-radius: 10px;
}

.form-control {
  box-shadow: none !important;
}

.form-body-section {
  padding: 30px 0px;
}

.form-submit {
  text-align: center;
  margin-top: 20px !important;
}

.form-heading {
  text-align: center;
  margin-top: 20px;
  padding-bottom: 5px;
  border-bottom: solid 1px;
  display: inline-block;
  margin-bottom: 0 !important;
}

.plain-icon:nth-child(1) {
  margin-right: 20px;
}

.content-height {
  min-height: 90vh;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px !important;
  /* overflow: hidden; */
}

.css-4jnixx-MuiStack-root {
  overflow: hidden !important;
}

.css-a643pv-MuiStack-root {
  padding-top: 0 !important;
}

/* .btn-close {
  position: absolute;
  top: 20px;
  right: 10px;
} */
td svg {
  font-size: 25px;
}

@media (max-width: 576px) {
  .form-body {
    width: 90%;
  }
}

/* .inlarge-image {
  top: -50px;
  left: -35px;
  display: block;
  z-index: 999;
  cursor: pointer;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-in-out;
} */

/*change the number below to scale to the appropriate size*/
/* .inlarge-image:hover {
  transform: scale(4); */
/* margin-top: 50px; */
/* position: absolute;
  top: 100px;
  left: 20px; */
/* } */

.image-zoom {
  transition: transform 0.2s;
}

/* .image-zoom:hover,
.image-zoom.hovered {
  transform: scale(5);
} */

.image-modal {
  height: 500px;
  width: 900px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  background-color: #fff;
  border: solid 1px #ccc;
}

.input-group-text {
  border-radius: 25px 0px 0px 25px !important;
  width: 200px;
  padding-left: 15px;
}

.css-bkzwk2-MuiStack-root {
  overflow: inherit !important;
}

.MuiStack-root.css-bkzwk2-MuiStack-root {
  width: 100% !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  display: none;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-style: none !important;
}

.input-group {
  margin: 10px 0px;
  background: white;
  border-radius: 25px;
}

.no-data-div .no-data-heading {
  text-align: center;
  padding: 80px 0px;
  margin: 0 auto;
  width: 70%;
  border: solid 1px #c8cff5;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 10%), 0 1px 2px -1px rgb(0, 0, 0, 10%);
  margin-top: 50px;
}

.cursorPointer {
  cursor: pointer;
}

.register-greetings span {
  display: inline-block;
  width: 100%;
  margin-bottom: 6px;
}

.register-greetings {
  background-color: rgba(255, 255, 255, 0.9) !important;

}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding-left: 15px !important;
}

.register-details {
  padding: 12px 0px;
  /* border-top: solid 1px #ccc; */
  /* border-bottom: solid 1px #ccc; */
  /* margin-top: 16px; */
}

/* .regis-greetings-bg {
  background-image: url(../src/assets/image/jgc-new-logo.png);
  background-position: center;
  background-size: 300px;
  background-repeat: no-repeat;
} */


/* jjs */