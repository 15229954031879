:root {
  --light-yellow: #f8e8da;
  --black-color: #000;
  --yellow-color: #f5d976;
}

body {
  background: #854857;
}

#body {
  background: #854857;
  margin: 0px;
  padding: 0px;
}

.imge-section {
  background: url(../images/print_demo.png);
  height: 450px;
  width: 300px;
  background-size: contain;
  background-repeat: no-repeat;
}

.id-text svg {
  height: 50px !important;
}

.home-section {
  display: flex;
}

.left-section {
  display: flex;
  /* align-items: center;
    justify-content: center;
    height: 100vh; */
}

.btn-primary {
  background: var(--yellow-color);
  border: none;
  border-radius: 50px;
  padding: 8px 20px;
  color: #000;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  /* margin: auto; */
  justify-content: center;
}

.btn:hover {
  background-color: var(--yellow-color);
  background: var(--yellow-color);
  color: var(--black-color);
}

.btn:first-child:active {
  background-color: var(--yellow-color);
  background: var(--yellow-color);
  color: var(--black-color);
}

.btn:first-child:active {
  background-color: var(--yellow-color);
  background: var(--yellow-color);
  color: var(--black-color);
}

.btn:focus-visible {
  background-color: var(--yellow-color);
  background: var(--yellow-color);
  color: var(--black-color);
}

:not(.btn-check) + .btn:active {
  background-color: var(--yellow-color);
  background: var(--yellow-color);
  color: var(--black-color);
}

.resister-tag {
  /* background: var(--yellow-color); */
  color: black;
  font-size: 24px;
  padding: 5px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 500;
}

.resister-tag:hover {
  color: var(--black-color);
}

.right-top-section {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}

.right-top-section p {
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: 10px;
}

form button {
  margin-left: auto;
}

form button svg {
  height: 14px;
  width: 20px;
  /* margin-top: 3px; */
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-section form {
  /* border: 1px solid #000; */
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  width: 100%;
  /* height: 80vh; */
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 221, 149, 0.62);
}

.form-control {
  border-radius: 50px;
  padding: 10px 15px;
}

.form-control::placeholder {
  color: #cdcdcd;
  font-size: 14px;
}

.right-logo {
  margin-left: auto;
}

.mobile-view {
  display: none;
}

.progress {
  color: var(--yellow-color);
  background-color: #f7e2b2;
  width: 60%;
  margin: auto;
  margin-bottom: 20px;
  /* margin-top: 20px; */
}

.progress-bar {
  color: var(--yellow-color);
  background-color: var(--yellow-color);
  width: 20px;
  border-radius: 20px;
}

.rubi-image .size-n1 {
  /* width: 78%; */
  mix-blend-mode: multiply;
  position: absolute;
  height: 40%;
  width: 40%;
  object-fit: contain;
  left: 10%;
  top: 20%;
}

.content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  height: 28vh;
}

.otp-input-box {
  display: flex;
  column-gap: 20px;
  margin-top: 40px;
}

.otp-input-box .otp-input {
  height: 40px;
  width: 40px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 100%;
  padding: 12px;
  font-size: 25px;
}

#body a {
  color: #000 !important;
  text-decoration: none !important;
}

.cutomer-details {
  display: flex;
  /* row-gap: 10px; */
  flex-direction: column;
  margin: 20px 0px;
}

.rmsc .dropdown-container {
  border: none !important;
}

.cutomer-details span {
  border-top: 1px solid #cdcdcd;
  font-size: 14px;
  font-weight: 400;
  padding: 4px;
  text-transform: capitalize;
  padding-left: 10px;
}

.rmsc .dropdown-heading {
  height: 100% !important;
}

.two-btn {
  display: flex;
  justify-content: space-between;
}

.cutomer-box {
  padding-top: 20px;
}

#nt-1 {
  height: 80vh;
}

.radio-btn {
  display: flex;
  column-gap: 10px;
  align-items: center;
  /* margin-left: 20px; */
  padding: 7px 20px;
}

input#fileUpload {
  margin: 8px 0px 10px;
}

/* body::after {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
 position: relative;
top: 0px;
}*/

.box {
  width: 100%;
  height: 400px;
  position: absolute;
  bottom: 0px;
  z-index: 12;
  background: white;
  left: 0px;
  border-radius: 40px 40px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}

.box span {
  text-align: center;
}

.ok {
  background: black;
  border: none;
  color: white;
  box-shadow: none;
  outline: none;
  cursor: pointer;
  border-radius: 50px;
  height: 40px;
  width: 80px;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background: rgb(0 0 0 / 13%);
}

.logo-text {
  color: white;
  font-weight: 400;
  font-size: 36px;
  /* text-transform: uppercase; */
  margin-right: 40px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.checkmark {
  width: 100px;
  height: 100px;
  position: relative;
}

.checkmark_circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  background-color: #5cb85c;
  position: absolute;
  z-index: 1;
}

.checkmark_stem {
  width: 20px;
  height: 40px;
  background-color: #fff;
  position: absolute;
  top: 28px;
  left: 45px;
  transform: rotate(-45deg);
  z-index: 10;
}

.checkmark_kick {
  width: 20px;
  height: 10px;
  background-color: #fff;
  position: absolute;
  top: 50px;
  left: 35px;
  transform: rotate(-45deg);
  z-index: 10;
}

.change-box {
  display: flex;
  justify-content: space-between;
}

.css-13cymwt-control {
  border-color: transparent !important;
  border-radius: 20px !important;
  width: 220px !important;
}

.css-1xc3v61-indicatorContainer {
  display: none !important;
}

.css-1xc3v61-indicatorContainer {
  display: none !important;
}

.css-t3ipsp-control {
  width: 220px;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

/* Hide the default radio button */
.change-box-02 {
  display: flex;
  flex-direction: column;
}

.radio-box {
  background: #fff;
  border-radius: 20px;
  padding: 7px 15px;
  display: flex;
}

.change-box-02 label {
  padding-left: 8px;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  padding-left: 76px;
  margin-top: 15px;
}
