@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

img {
  max-width: 100%;
}

.navbar-expand-lg .navbar-nav {
  gap: 40px;
}

.navbar-brand img {
  width: 80px;
}

.btn-primary.regis {
  background-color: #c3002f;
  border-color: #c3002f;
}

.btn-primary.regis:hover {
  background-color: #ce2950;
  border-color: #ce2950;
}

/* .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.65);
  padding: 8px 0px !important;
  transition: all 0.5s;
} */
/* .navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: rgba(255, 255, 255, 1);
  border-bottom: solid 1px #e3ae24;
} */

.bg-navbar {
  background-color: #fff;
  position: fixed;
  /* position: sticky; */
  top: 0;
  z-index: 1;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.footer-bg {
  background-color: #fff;
  padding: 30px;
  color: #fff;
  text-align: center;
}

.footer-bg p {
  color: #000 !important;
}

.footer-bg a {
  color: #000 !important;
}

.organisers {
  max-width: 100px;
  margin-bottom: 16px;
}

/* .slider-bg.one {
  background-image: url(../../src/assets/image/group-pic.JPG);
}

.slider-bg.two {
  background-image: url(../../src/assets/image/slider-two.jpg);
}

.slider-bg.three {
  background-image: url(../../src/assets/image/slider-three.jpg);
  background-position: top;
} */

.slider-bg {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 600px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  /* display: flex;
  align-items: center; */
  position: relative;
}

.slider-color-bg {
  background-color: rgba(0, 0, 0, 0.6);
  height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.slider-img-tag {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-grey {
  background-color: #fafafa;
}

p {
  color: #555c63 !important;
}

.slider-text {
  color: #fff;
  text-align: center;
  padding-top: 80px;
  padding-top: 80px;
}

.slider-text h1 {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.9);
  -webkit-text-fill-color: transparent;
  font-size: 100px;
}

.section-padding {
  padding: 60px 0px;
}

.slider-box {
  background-color: #fff;
  padding: 30px 30px;
  text-align: center;
  border-bottom: 5px solid #c3002f;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.slider-services {
  margin-top: -100px;
}

.slider-box img {
  width: 50px;
  margin-bottom: 20px;
}

.slider-box h5 {
  margin-bottom: 20px !important;
}

.about-us-section {
  text-align: center;
}

.about-us-section .about-content {
  text-align: center;
  line-height: 1.8;
  font-size: 20px;
}

.our-story-hide {
  max-height: 0;
}

.our-story-div {
  transition: max-height 0.5s ease-in-out;
  max-height: 0;
  overflow: hidden;
  margin-top: 24px;
}

.our-story-show {
  max-height: 2000px;
}

.president-text {
  line-height: 1.8;
  font-size: 20px;
  color: #fff !important;
}

.about-text h2 {
  margin-bottom: 20px !important;
}

.about-text p {
  line-height: 1.7;
  font-size: 20px;
}

.about-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.about-list li {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  width: 48%;
  padding: 5px 0px;
}

.fashion-section-bg {
  background-image: url(../../src/assets/image/fashion-background.png);
  background-position: center;
  background-size: cover;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
}

/* .fashion-img-bg {
  background-image: url(../../src/assets/image/fashion-bg.png);
  background-position: center;
  background-size: fill;
  background-attachment: fixed;
  background-repeat: no-repeat;
} */

/* .about-list i, */
.about-list svg {
  font-size: 30px;
  color: #c3002f;
  margin-right: 10px;
}

.upcoming-events-bg {
  background-color: #222d35;
}

.upcoming-event-img {
  padding: 30px;
  background-color: #fff;
}

.upcoming-event-text {
  margin-top: 60px;
  margin-bottom: 60px;
}

.upcoming-paragraph {
  font-size: 20px;
  line-height: 1.7;
}

.upcoming-paragraph.black {
  color: #000 !important;
}

.members-tabs {
  border: solid 1px #c3002f;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
}

.members-tabs.margin {
  margin-top: 60px;
}

.members-section {
  background-color: #212529;
}

.executive-members-tab {
  border: solid 1px #c3002f;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  background-color: #fff;
  height: 100%;
}

.executive-members-tab img {
  max-width: 100%;
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 150px;
  object-position: center;
}

/* .members-tabs .image {
  max-height: 150px;
} */

.members-tabs img {
  max-width: 100%;
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 20px;
  height: 350px;
  object-position: center;
}

.modal-content {
  max-width: 100% !important;
}

.regis-form-group input[type="text"] {
  background: transparent;
  /* border: none; */
  outline: none;
  /* color: #fff; */
  width: 100%;
  height: 40px;
}

.regis-form-group button[type="button"] {
  height: 40px;
  transition: all 0.5s;
  width: 100% !important;
}

.regis-form-group .btn-secondary {
  background-color: #fff !important;
  color: #c3002f !important;
  width: 100%;
  height: 100%;
}

.dcChUd {
  margin: 0 !important;
  height: 100% !important;
  background-color: #c3002f !important;
}

.slider-row {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.slider-row h3 {
  color: #000 !important;
}

.cYcnau,
.DkxSo {
  background-color: #ccc !important;
}

.ashok-text {
  text-align: center;
  padding: 30px 0px;
  width: 90%;
  margin: 0 auto;
}

.ashok-text p {
  font-size: 20px !important;
  color: #fff !important;
}

.fashion-text {
  padding: 0px 0.75rem;
}

footer {
  color: #000 !important;
}

.members-heading {
  /* background-color: #deaf44; */
  padding: 20px 30px;
  text-align: center;
  margin-bottom: 24px;
  border-radius: 10px;
  background-image: radial-gradient(circle 763px at 18.3% 24.1%, rgba(255, 249, 137, 1) 7.4%, rgba(226, 183, 40, 1) 58.3%);
}

.manager-section {
  position: relative;
}

.golden-bg {
  position: absolute;
  height: 100px;
  background-image: radial-gradient(circle 763px at 18.3% 24.1%, rgba(255, 249, 137, 1) 7.4%, rgba(226, 183, 40, 1) 58.3%);
  width: 100%;
  top: 30%;
}


@media (max-width: 992px) {
  .slider-box {
    margin-top: 25px;
  }

  .about-img {
    text-align: center;
  }

  .upcoming-event-img {
    text-align: center;
    margin-top: 25px;
  }

  .about-img img {
    max-width: 100% !important;
  }

  .members-tabs.margin {
    margin-top: 0;
  }
}

@media (max-width: 576px) {
  .fashion-text {
    background-color: #c3002f !important;
  }

  .slider-text h1 {
    font-size: 60px;
  }

  .section-padding {
    padding: 30px 0px;
  }

  .president-text {
    margin-top: 20px;
    text-align: center;
  }

  .bg-grey.mt-5 {
    margin-top: 0 !important;
  }

  .about-text {
    margin-top: 20px;
    text-align: center;
  }

  .members-tabs {
    margin-bottom: 20px;
  }

  .members-tabs br {
    display: none;
  }

  .upcoming-event-text {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .footer-bg a {
    display: block;
  }

  .executive-members-tab img {
    height: 350px !important;
  }
}




