@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Poppins:wght@500;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.barcode-text-scanning {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  /* background-color: #f1f1f1; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.barcode-text-scanning .form-container {
  background-color: #fff;
  padding: 80px;
  border-radius: 10px;
  box-shadow: 4px 4px #dcdcdc;
  max-width: 683px;
  width: 100%;
  margin: 30px auto;
}

.barcode-text-scanning h1 {
  font-family: "Merriweather", serif;
  font-weight: bold;
  font-size: 42px;
  margin-bottom: 10px;
}

.barcode-text-scanning p {
  font-size: 22px;
  margin-bottom: 40px;
}

.barcode-text-scanning label[for="email"] {
  font-weight: 600;
  color: #6d6d6d;
}

.barcode-text-scanning input[type="email"] {
  max-width: 483px;
  width: 100%;
  padding: 20px;
  margin: 10px 0;
  border: 2px solid #000;
  border-radius: 4px;
  font-size: 16px;
  margin-bottom: 20px;
}

.barcode-text-scanning input[type="email"]::placeholder {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.barcode-text-scanning .checkbox-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
}

.barcode-text-scanning.checkbox-container label {
  display: block;
  margin-bottom: 20px;
}

.barcode-text-scanning input[type="checkbox"] {
  height: 38px;
  width: 38px;
  vertical-align: middle;
  accent-color: #000;
}

.barcode-text-scanning a,
.barcode-text-scanning a:visited {
  color: #4067f3;
  text-decoration: none;
}

.barcode-text-scanning button {
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-weight: 18px;
  margin: 20px auto;
  transition: background-color 0.4s ease-in-out;
}

.barcode-text-scanning button:hover {
  background-color: #444;
}

.barcode-text-scanning .privacy-notice {
  max-width: 483px;
  width: 100%;
  font-size: 16px;
  margin: 10px 0;
}

@media (max-width: 720px) {
  .barcode-text-scanning .form-container {
    width: 90%;
  }
}

@media (max-width: 500px) {

  .barcode-text-scanning,
  .barcode-text-scanning .privacy-notice {
    font-size: 14px;
  }

  h1 {
    font-size: 30px;
  }

  p {
    font-size: 16px;
  }

  .barcode-text-scanning .checkbox-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .barcode-text-scanning input[type="checkbox"] {
    height: 20px;
    width: 20px;
  }
}




.otp-div {
  background-color: #fff;
  padding: 80px;
  border-radius: 10px;
  box-shadow: 4px 4px #dcdcdc;
  max-width: 683px;
  width: 100%;
  margin: 30px auto;
}

.otp-div .container {
  background-color: #1e1e1e;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-div h1 {
  margin-bottom: 1rem;
  color: #ffffff;
}

.otp-div .otp-input {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.otp-div .otp-input input {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid #444;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #ffffff;
}

.otp-div .otp-input input::-webkit-outer-spin-button,
.otp-div .otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-div .otp-input input[type=number] {
  -moz-appearance: textfield;
}

.otp-div button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 5px;
}

.otp-div button:hover {
  background-color: #45a049;
}

.otp-div button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.otp-div #timer {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ff9800;
}



.vantaDiv .vanta-canvas {
  width: 100vw;
  height: 100vh;
  right: 0px;
  left: 0px;
  margin: 0 auto;
  z-index: 9889;

}